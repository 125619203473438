<template>
  <section class="section6 text-center centerDiv">
    <div class="container ">
      <div class="row justify-content-center">
        <div class="col-12">
          <div class="counterDiv" style='background: url("images/countdown.png");'> 
            <badge :label="counter"></badge><!-- <span class="text-white">1</span> -->
          </div>
          <h5 class="blueTxt mb-5">{{ $t('main.countdown.get_ready') }}</h5>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from 'vuex'
import Badge from '~/components/Badge.vue'
import AudioAnalyser from "~/js/AudioAnalyser.js"

export default {
  components: {
    Badge,
  },

  data () {
    return {
      counter: 4,
      to: null,
      analyser: null,
      
      ambientArray: [],
    }
  },

  computed: {
    ...mapGetters({
      stream: 'inhaler/stream',
      selectedDevice: 'inhaler/selectedDevice',
    })
  },

  async created() {
    // reset flow
    this.$store.dispatch('inhaler/clearFlow')
  
    await this.$store.dispatch('inhaler/fetchAudioStream')

    this.analyser = new AudioAnalyser(this.stream, this.analyserSetValueHandler)
    this.analyser.init()
    this.analyser.start()

    this.countdown()
  },

  destroyed() {
    if (this.to != null) clearTimeout(this.to)

    this.analyser.stop()
  },

  methods: {
    countdown() {
      this.counter--
      if (this.counter == 0) {
        this.reachedEnd()
      }else{
        this.to = setTimeout(this.countdown, 1000)
      }
    },

    analyserSetValueHandler(audioLevel) {
      this.ambientArray.push(audioLevel)
    },

    reachedEnd() {
      this.$router.push('/main/inhaler')
      this.analyser.stop()

      // calculate ambient : 
      // - sort desc
      // - get average of first 10% to 50% of array

      this.ambientArray.sort((a, b) => b - a)

      const minIndex = 0.1
      const maxIndex = 0.5

      const count = Math.ceil(this.ambientArray.length * (maxIndex - minIndex))
      const startCount = Math.floor(this.ambientArray.length * minIndex)

      let total = 0;
      let ambientLevel = 0;
      
      for (var a = startCount; a < count; a++) {
        total += this.ambientArray[a];
      }
      ambientLevel = Math.floor(total / count);

      this.$store.dispatch('inhaler/setAmbient', ambientLevel)

    },
  },
}

</script>