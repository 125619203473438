<template>
  <span class="text-white">
    {{label}}
  </span>
</template>

<script>
export default {
  components: {
    //Spinner,
  },
  props: [
    'label',
  ],
}
</script>